import $ from "jquery";

// Generated by CoffeeScript 1.10.0
const shareTitle = function (link: HTMLElement) {
  return $(link).closest(".share").data("title") as string;
};

const shareUrl = function (link: HTMLElement) {
  return $(link).closest(".share").data("href") as string;
};

const mediaUrl = function (link: HTMLElement) {
  return $(link).closest(".share").data("media") as string;
};

const tweetUrl = function (text: string, url: string) {
  return (
    "https://twitter.com/intent/tweet" +
    "?text=" +
    encodeURIComponent(text) +
    "&url=" +
    encodeURIComponent(url)
  );
};

const pinterestUrl = function (text: string, url: string, media: string) {
  return (
    "https://www.pinterest.com/pin/create/button/" +
    "?url=" +
    encodeURIComponent(url) +
    "&media=" +
    encodeURIComponent(media) +
    "&description=" +
    encodeURIComponent(text)
  );
};

const ready = function () {
  $(".share .share-facebook").click(function () {
    window.FB.ui({
      method: "share",
      href: shareUrl(this)
    });
    return false;
  });
  $(".share .share-twitter").click(function () {
    window.open(tweetUrl(shareTitle(this), shareUrl(this)), "_blank");
    return false;
  });
  return $(".share .share-pinterest-p").click(function () {
    window.open(
      pinterestUrl(shareTitle(this), shareUrl(this), mediaUrl(this)),
      "_blank"
    );
    return false;
  });
};

$(document).ready(ready);
$(document).on("page:load", ready);
