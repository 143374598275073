import $ from "jquery";

const ready = function () {
  $(".expandable").each(function () {
    const $expanded = $(this).find(".expanded");
    const $showMore = $(this).find(".show-more");
    $expanded.hide();
    $showMore.find("a").click(function () {
      $showMore.hide();
      $expanded.slideDown();
    });
  });
};

$(document).ready(ready);
$(document).on("page:load", ready);
